import React, { useState, useEffect } from "react";

// 解析 URL 中的 _ads 參數
function parseCurrentUriAds() {
  try {
    const url = new URL(window.location.href);
    const adsValue = url.searchParams.get("_ads"); // 使用 searchParams.get 更可靠
    return adsValue || null; // 如果沒有 _ads，返回 null
  } catch (error) {
    console.error("Error parsing URL:", error.message);
    return null;
  }
}

export const Header = (props) => {
  const [links, setLinks] = useState({
    //line: "https://liff.line.me/2006508428-LaMj0Q6M",
    line: "line://app/2006508428-LaMj0Q6M",
    web: "https://play.yhd.tw",
  });

  useEffect(() => {
    const adsStr = parseCurrentUriAds();
    console.log("parseCurrentUriAds: " + adsStr);
    if (adsStr) {
      setLinks((prevLinks) => {
        const updatedLinks = {};
        Object.keys(prevLinks).forEach((key) => {
          try {
            const url = new URL(prevLinks[key]);
            url.searchParams.set("_ads", adsStr);
            updatedLinks[key] = url.toString();
          } catch (error) {
            console.error(`Error updating ${key} link:`, error.message);
            updatedLinks[key] = prevLinks[key]; // 保留原始連結
          }
        });
        return updatedLinks;
      });
    }
  }, []); // 空依賴陣列，只在初次渲染時執行

  return (
    <header id="header">
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-md-12 intro-text">
              <a href={links.line} className="btn btn-intro page-scroll">
                <img src="img/intro-btn-line.png" className=" " alt="" />
              </a>{" "}
              <a href={links.web} className="btn btn-intro page-scroll">
                <img src="img/intro-btn-web.png" className=" " alt="" />
              </a>{" "}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};