import { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};

// 解析 URL 中的 _ads 參數
function parseCurrentUriAds() {
  try {
    const url = new URL(window.location.href);
    const adsValue = url.searchParams.get("_ads"); // 使用 searchParams.get 更可靠
    return adsValue || null; // 如果沒有 _ads，返回 null
  } catch (error) {
    console.error("Error parsing URL:", error.message);
    return null;
  }
}

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [links, setLinks] = useState({
    line: "line://app/2006508428-LaMj0Q6M",
    web: "https://play.yhd.tw",
    services: props.data ? props.data.services : "/",
    privacy: props.data ? props.data.privacy : "/",
    gamerule: props.data ? props.data.gamerule : "/",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    emailjs
      .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_PUBLIC_KEY")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  useEffect(() => {
    const adsStr = parseCurrentUriAds();
    console.log("parseCurrentUriAds: " + adsStr);
    if (adsStr) {
      setLinks((prevLinks) => {
        const updatedLinks = {};
        Object.keys(prevLinks).forEach((key) => {
          const url = new URL(prevLinks[key]);
          url.searchParams.set("_ads", adsStr);
          updatedLinks[key] = url.toString();
        });
        return updatedLinks;
      });
    }
  }, [props.data]); // 當 props.data 改變時更新連結

  return (
    <div>
      <div id="contact">
        <div className="container text-center">
          <div className="col-md-8 col-md-offset-2 contact-info">
            <div className="row">
              <div className="col-md-6 col-xs-6">
                <a href={links.line} className="btn page-scroll">
                  <img src="img/btn-line.png" className=" " alt="" />
                </a>{" "}
              </div>
              <div className="col-md-6 col-xs-6">
                <a href={links.web} className="btn page-scroll">
                  <img src="img/btn-web.png" className=" " alt="" />
                </a>{" "}
              </div>
            </div>
            <div className="contact-text">
              <p>{props.data ? props.data.p1 : "loading"}</p>
              <p>{props.data ? props.data.p2 : "loading"}</p>
            </div>
            <div className="col-md-12 col-xs-12 contact-img">
              <img src="img/logo.png" className=" " alt="" />
              <img src="img/contact-mycard.jpg" className=" " alt="" />
              <img src="img/contact-18up.jpg" className=" " alt="" />
            </div>
          </div>
          <div className="col-md-8 col-md-offset-2 contact-icon-area">
            <div className="row">
              <div className="col-md-3 col-xs-6">
                <div className="contact-icon">
                  <div className="contact-icon-image">
                    <img src="img/contact-icon-maintains.png" className="" alt="" />
                  </div>
                  <div className="contact-icon-meta">
                    <p>
                      客服服務<br></br>
                      周一到周五
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-xs-6">
                <div className="contact-icon">
                  <div className="contact-icon-image">
                    <img src="img/contact-icon-services.png" className="" alt="" />
                  </div>
                  <div className="contact-icon-meta">
                    <p>
                      服務時間<br></br>
                      10:00~22:00
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-xs-6">
                <div className="contact-icon">
                  <div className="contact-icon-image">
                    <img src="img/contact-icon-line.png" className="" alt="" />
                  </div>
                  <div className="contact-icon-meta">
                    <p>
                      官方Line<br></br>
                      <a href="https://line.me/ti/p/@YHD888">@YHD888</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-xs-6">
                <div className="contact-icon">
                  <div className="contact-icon-image">
                    <img src="img/contact-icon-mail.png" className="" alt="" />
                  </div>
                  <div className="contact-icon-meta">
                    <p>
                      客服信箱<br></br>
                      service@yhd.tw
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="contact-privacy">
                <ul>
                  <li>
                    <a href={links.services} target="blank">
                      <span>服務條款</span>
                    </a>
                  </li>
                  <li>|</li>
                  <li>
                    <a href={links.privacy} target="blank">
                      <span>隱私政策</span>
                    </a>
                  </li>
                  <li>|</li>
                  <li>
                    <a href={links.gamerule} target="blank">
                      <span>遊戲規章</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>© 2022 All right reserved 定城實業有限公司 版權所有</p>
        </div>
      </div>
    </div>
  );
};