import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Depost } from "./components/depost";
import { Vip } from "./components/vip";
import { Services } from "./components/services";
import { Privacy } from "./components/privacy";
import { Rules } from "./components/rules";
import { Hotgame } from "./components/hotgame";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

// 解析 URL 中的 _ads 參數
function parseCurrentUriAds() {
  try {
    const url = new URL(window.location.href);
    const adsValue = url.searchParams.get("_ads"); // 使用 searchParams.get 更可靠
    return adsValue || null; // 如果沒有 _ads，返回 null
  } catch (error) {
    console.error("Error parsing URL:", error.message);
    return null;
  }
}

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [adsValue, setAdsValue] = useState(null);

  useEffect(() => {
    setLandingPageData(JsonData); // 確保數據始終加載
    const adsStr = parseCurrentUriAds();
    console.log("parseCurrentUriAds:", adsStr); // 檢查 ads 值
    setAdsValue(adsStr);
  }, []);

  // 添加條件渲染，確保數據加載前顯示載入中
  if (!landingPageData || Object.keys(landingPageData).length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Navigation adsValue={adsValue} />
      <Header data={landingPageData.Header} />
      <Hotgame data={landingPageData.Hotgame} />
      <Vip data={landingPageData.Vip} />
      <Depost data={landingPageData.Depost} />
      {/* <Services data={landingPageData.Services} />
      <Privacy />
      <Rules /> */}
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
