import React from "react";

export const Hotgame = (props) => {
  return (
    <div id="hotgame" className="text-center">
      <div className="container">
        <div className="col-md-12 section-title">
          <img src="img/title-hotgame.png" className="img-title" alt="" />{" "}
          <h3>
          各種類型遊戲 應有盡有<br></br>
          感受簡單好上手、最豐富的娛樂體驗</h3>
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-2 col-sm-2 col-xs-4 hotgame">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="hotgame-img" />
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
