import React from "react";

export const Depost = (props) => {
  return (
    <div id="depost" className="text-center">
      <div className="container">
        <div className="col-md-12">
          <img src="img/depost.png" className="img-content" alt="" />
        </div>
      </div>
    </div>
  );
};
