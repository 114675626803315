import React from "react";

export const Vip = (props) => {
  return (
    <div id="vip">
      <div className="container">
        <div className="col-md-12 section-title text-center">
          <img src="img/title-vip.png" className="img-title" alt="" />
        </div>
        <div className="row text-center">
          <div className="col-xs-12 col-md-12">
            <div className="vip-text">
              <h3>輕鬆升級，即可享受更進階的體驗！</h3>
              <div className="list-style">
                <div className="col-md-3 col-sm-3"></div>
                <div className="col-md-3 col-sm-3 col-xs-6">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-md-3 col-sm-3 col-xs-6">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-12">
            {" "}
            <img src="img/vip.png" className="img-content" alt="" />
            {" "}
          </div>

        </div>
      </div>
    </div>
  );
};
